<template>
	<router-view />
</template>

<script>
export default {};
</script>

<style>
@import '@/style.css';
/* @import './assets/css/color-dark.css';
@import './assets/css/elementPlus.css'; */
</style>
