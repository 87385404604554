import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import { loadingInit } from '@/utils/loading.js'

// 移动端适配
import 'amfe-flexible'
// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()

// import './assets/css/icon.css'
const app = createApp(App)

// if (window.location.host == "h5test.tuplate.com") {
//   app.use(vConsole)
// }
app
  .use(store)
  .use(router)
  .mount('#app')
/**
* 全集加载框
* @param {*} 加载的dom 默认body
*/
// app.config.globalProperties.$loading = loadingInit
