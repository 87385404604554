import { createRouter, createWebHashHistory } from "vue-router";
// import experimentRouter from './experiment'
// import layerRouter from './layer'
// import reportRouter from './report'

const routes = [
  // {
  //   path: '/',
  //   redirect: '/dashboard'
  // }, 
  {
    path: '/user_agree',
    component: () => import('@/views/user_agree.vue'),
    name: 'user_agree',
    meta: {
      title: '图板用户协议'
    }
  },
  {
    path: '/policy',
    component: () => import('@/views/policy.vue'),
    name: 'policy',
    meta: {
      title: '图板隐私政策'
    }
  },
  {
    path: '/share',
    component: () => import('@/views/share.vue'),
    name: 'share',
    meta: {
      title: '下载页'
    }
  }, 
  {
    path: '/vip_membership_agreement',
    component:() => import('@/views/vip_membership_agreement.vue'),
    name: 'vip_membership_agreement',
    meta: {
      title: 'VIP服务协议'
    }
  }, 
  {
    path: '/vip_subscribe_agreement',
    component:() => import('@/views/vip_subscribe_agreement.vue'),
    name: 'vip_subscribe_agreement',
    meta: {
      title: 'VIP会员服务协议'
    }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});

export default router;